.link{
    transition: all 1s ease;
    margin:20px auto;
}
.link:hover{
    transform: scale(1.2)
}
@media (max-width:480px){
   .link{ text-align:center;
   }
  }