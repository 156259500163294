*{
  margin:0 0;
  padding:0 0;
}
body{
  scroll-behavior: smooth;
}
.loading{
  transform:scale(0.4)
}
.con{
  background-color: rgb(90, 86, 86);
}

:root{
--box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
--blue-back:rgb(8, 8, 56,0.8);

 
}